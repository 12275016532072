
import './App.css';
import { Route, Routes} from 'react-router-dom'
import Header from './Components/Home';
import Team from './Components/Team';
import Store from './Components/Store';
import Home from './Components/Home';
import Fixture from './Components/Fixture';
import Sponsers from './Components/Sponsers';
import About from './Components/About';
import Contact from './Components/Contact';
import SignUp from './Components/SignUp';
import Login from './Components/Login';
import Constitution from './Components/Constitution';
import Privacy from './Components/Privacy';
import Dash from './Components/Dash';
import Profile from './Components/Profile';

function App() {
  return (
    <div>
        <Routes>
            <Route path={"/"} element={<Home />} />
            <Route index path={"/Home"} element={<Home/>} />
            <Route index path={"/team"} element={<Team/>} />
            <Route index path={"/store"} element={<Store/>} />
            <Route index path={"/fixture"} element={<Fixture/>}/>
            <Route index path={"/sponser"} element={<Sponsers/>}/>
            <Route index path={"/About"} element={<About/>}/>
            <Route index path ={"/Contact"} element ={<Contact/>}/>
            <Route index path ={"/Signup"} element ={<SignUp/>}/>
            <Route index path ={"/login"} element ={<Login/>}/>
            <Route index path ={"/Dash"} element ={<Dash/>}/>
            <Route index path ={"/constitution"} element ={<Constitution/>}/>
            <Route index path ={"/privacy"} element ={<Privacy/>}/>
            <Route index path ={"/profile"} element ={<Profile/>}/>
        </Routes>
    </div>
  );
}

export default App;
