import React from 'react'
import image1 from '../Images/logo.svg';
import {  useNavigate } from 'react-router-dom';
export default function Footer() {
    const navigate = useNavigate();
    const Team=()=>{
        navigate('/team' ,{replace:true})  }
        const Contact=()=>{
            navigate('/contact' ,{replace:true})  }
        const constitution=()=>{
            navigate('/constitution' ,{replace:true})  }
            const privacy=()=>{
                navigate('/privacy' ,{replace:true})  }
  return (
    <div>
        <footer b-rkosd1qa8t="" className="section-sm bg-tertiary">
	<div b-rkosd1qa8t="" className="container">
		<div b-rkosd1qa8t="" className="row justify-content-between">
			<div b-rkosd1qa8t="" className="col-lg-2 col-md-4 col-6 mb-4">
				<div b-rkosd1qa8t="" className="footer-widget">
					<h5 b-rkosd1qa8t="" className="mb-4 text-primary font-secondary">Sponsors</h5>
					<ul b-rkosd1qa8t="" className="list-unstyled">
						<li b-rkosd1qa8t="" className="mb-2"><a b-rkosd1qa8t="" href="https://xpotential.co" target='blank'>Xpotential</a>
						</li>
							<li b-rkosd1qa8t="" className="mb-2">
								<a b-rkosd1qa8t="" href="https://depauseit.com" target='blank'>DepauseIT</a>
							</li>
						<li b-rkosd1qa8t="" className="mb-2"><a b-rkosd1qa8t="" href="https://prepprime.com" target='blank'>Prep Prime</a>
						</li>
							<li b-rkosd1qa8t="" className="mb-2">
								<a b-rkosd1qa8t="" href="https://thecoastalmedicalgroup.com/" target='blank'>Costal Medical Group</a>
						</li>
							<li b-rkosd1qa8t="" className="mb-2">
								<a b-rkosd1qa8t="" href="https://thesteakspot.com/" target='blank'>Steak Spot</a>
						</li>
					</ul>
				</div>
			</div>
			<div b-rkosd1qa8t="" className="col-lg-2 col-md-4 col-6 mb-4">
				<div b-rkosd1qa8t="" className="footer-widget">
					<h5 b-rkosd1qa8t="" className="mb-4 text-primary font-secondary">About</h5>
					<ul b-rkosd1qa8t="" className="list-unstyled">
							<li b-rkosd1qa8t="" className="mb-2">
								<a b-rkosd1qa8t="" href="" onClick={()=>constitution()}>Constitution</a>
							</li>
						<li b-rkosd1qa8t="" className="mb-2"><a b-rkosd1qa8t="" href="#!">Coaching Academy</a>
						</li>
						<li b-rkosd1qa8t="" className="mb-2"><a b-rkosd1qa8t="" href="#!">Careers</a>
						</li>
						<li b-rkosd1qa8t="" className="mb-2"><a b-rkosd1qa8t="" href="#!">Our Story</a>
						</li>
						<li b-rkosd1qa8t="" className="mb-2"><a b-rkosd1qa8t="" href="" onClick={()=>Team()}>Team</a>
						</li>
					</ul>
				</div>
			</div>
			<div b-rkosd1qa8t="" className="col-lg-2 col-md-4 col-6 mb-4">
				<div b-rkosd1qa8t="" className="footer-widget">
					<h5 b-rkosd1qa8t="" className="mb-4 text-primary font-secondary">Help</h5>
					<ul b-rkosd1qa8t="" className="list-unstyled">
						<li b-rkosd1qa8t="" className="mb-2"><a b-rkosd1qa8t="" href="" onClick={()=>Contact}>Contact Us</a>
						</li>
						<li b-rkosd1qa8t="" className="mb-2"><a b-rkosd1qa8t="" href="#!">FAQs</a>
						</li>
					</ul>
				</div>
			</div>
			<div b-rkosd1qa8t="" className="col-lg-4 col-md-12 newsletter-form">
				<div b-rkosd1qa8t="" style={{backgroundColor: '#F4F4F4', padding: '35px'}}>
					<h5 b-rkosd1qa8t="" className="mb-4 text-primary font-secondary">Subscribe</h5>
					<div b-rkosd1qa8t="" className="pe-0 pe-xl-5">
						<form action="#!" method="post" name="mc-embedded-subscribe-form" target="_blank">
							<div b-rkosd1qa8t="" className="input-group mb-3">
                            <input b-rkosd1qa8t="" type="text" className="form-control shadow-none bg-white border-end-0" placeholder="Email address"/> <span b-rkosd1qa8t="" className="input-group-text border-0 p-0">
                    <button b-rkosd1qa8t="" className="input-group-text border-0 bg-primary" type="submit" name="subscribe" aria-label="Subscribe for Newsletter"><i b-rkosd1qa8t="" className="fas fa-arrow-right"></i></button>
                  </span>
						</div>
							<div b-rkosd1qa8t="" style={{position:'absolute', left: '-5000px'}} aria-hidden="true">
								<input b-rkosd1qa8t="" type="text" name="SubscriberEmail" tabindex="-1"/>
							</div>
						</form>
					</div>
					<p b-rkosd1qa8t="" className="mb-0">Subscribe and join our email list for upcoming events and engagements, be a part of fastest growing cricket community.</p>
				</div>
			</div>
		</div>
		<div b-rkosd1qa8t="" className="row align-items-center mt-5 text-center text-md-start">
			<div b-rkosd1qa8t="" className="col-lg-4">
        <a b-rkosd1qa8t="" href="index.htm">
          <img b-rkosd1qa8t="" loading="prelaod" decoding="async" className="img-fluid" width="160" src={image1} alt="Houston Chargers"/>
        </a>
			</div>
			<div b-rkosd1qa8t="" className="col-lg-4 col-md-6 mt-4 mt-lg-0">
				<ul b-rkosd1qa8t="" className="list-unstyled list-inline mb-0 text-lg-center">
					<li b-rkosd1qa8t="" className="list-inline-item me-4"><a b-rkosd1qa8t="" className="text-black" href="" onClick={()=>privacy()}>Privacy Policy</a>
					</li>
					<li b-rkosd1qa8t="" className="list-inline-item me-4"><a b-rkosd1qa8t="" className="text-black" href="#!">Terms &amp; Conditions</a>
					</li>
				</ul>
			</div>
			<div b-rkosd1qa8t="" className="col-lg-4 col-md-6 text-md-end mt-4 mt-md-0">
				<ul b-rkosd1qa8t="" className="list-unstyled list-inline mb-0 social-icons">
						<li b-rkosd1qa8t="" className="list-inline-item me-3">
							<a b-rkosd1qa8t="" title="Houston Chargers - Facebook" className="text-black" href="https://www.facebook.com/houstonchargers/" target='blank'><i b-rkosd1qa8t="" className="fab fa-facebook-f"></i></a>
					</li>
					<li b-rkosd1qa8t="" className="list-inline-item me-3"><a b-rkosd1qa8t="" title="Houston Chargers - Twitter" className="text-black" target='blank' href="https://twitter.com/houstonchargers"><i b-rkosd1qa8t="" className="fab fa-twitter"></i></a>
					</li>
						<li b-rkosd1qa8t="" className="list-inline-item me-3">
							<a b-rkosd1qa8t="" title="Houston Chargers - Instagram" className="text-black" href="https://www.instagram.com/houston.chargers/" target='blank'><i b-rkosd1qa8t="" className="fab fa-instagram"></i></a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</footer>
    </div>
  )
}
