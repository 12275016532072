import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import image1 from '../Images/logo.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import user1 from '../Images/download.png';
import './Style.css';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
  // Mock user data
  const [formData, setFormData] = useState({
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '123-456-7890',
    address: '123 Main St, Anytown, USA',
    bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.'
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Save formData to server or local storage here
    toast.success('Your profile has been updated successfully! Our team will contact you later if needed.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
    const navigate = useNavigate();
    
    const home=()=>{
        navigate('/home' ,{replace:true})  }
        const Team=()=>{
            navigate('/team' ,{replace:true})  }
            const Store=()=>{
                navigate('/store' ,{replace:true})  }
          const Fixture=()=>{
              navigate('/fixture' ,{replace:true})  }
              const Sponser=()=>{
                  navigate('/sponser' ,{replace:true})  }
          const About =()=>{
              navigate('/about' ,{replace:true})  }
          const Contact=()=>{
              navigate('/contact' ,{replace:true})  }
              const handleLogout = () => {
      navigate('/home', { replace: true });
              }
    const profile =()=>{

    navigate('/profile' ,{replace : true})}
   
    
  return (
    <div>
    <div>        <header b-rkosd1qa8t="" className="navigation bg-tertiary fixed-top">
    <nav b-rkosd1qa8t="" className="navbar navbar-expand-xl navbar-light text-center py-3 navbar-fixed-top" style={{zIndex: 1000}}>

    <div b-rkosd1qa8t="" className="container">
        <a b-rkosd1qa8t="" className="navbar-brand" href="index.htm">
            <img b-rkosd1qa8t="" loading="prelaod" decoding="async" className="img-fluid" width="160" src={image1} alt="Wallet"/>
        </a>
        <button b-rkosd1qa8t="" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span b-rkosd1qa8t="" className="navbar-toggler-icon"></span>
        </button>
        <div b-rkosd1qa8t="" className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul b-rkosd1qa8t="" className="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t className="nav-link" href="" onClick={()=>home()}>Home</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Team()}>Team</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Store()}>Store</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Fixture()}>Fixtures</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=> Sponser()}>Sponsors</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" href="#!">Follow</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>About()}>About</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Contact()}>Contact</a>
                    </li>
            </ul>
            <div className="dropdown ms-sm-3 header-item topbar-user">
            <a b-rkosd1qa8t=""  className="btn btn-primary ms-2 ms-lg-3" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <span className="d-flex align-items-center">
                    <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar"/>
                    <span className="text-start ms-xl-2">
                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">UserName</span>
                    
                    </span>
                </span>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
            
                <h6 className="dropdown-header">Welcome!</h6>
                <a className="dropdown-item" href="" onClick={()=>profile()} ><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" ></i> <span className="align-middle">Profile</span></a>
                <a className="dropdown-item" href="#!"><i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Help</span></a>
                <div className="dropdown-divider"></div>
               
                <a className="dropdown-item" href="#!"><i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></a>
                <a className="dropdown-item" href="" onClick={()=>handleLogout()}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1" ></i> <span className="align-middle" data-key="t-logout">Logout</span></a>
            </div>
        </div>
        </div>
    </div>
</nav>
</header></div>
      <ToastContainer />
      <section className="section profile">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="card">
                <div className="card-body text-center">
                  <img src={user1} className="rounded-circle mb-3" alt="User Avatar" width="150" />
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="address">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="bio">Bio</label>
                      <textarea
                        className="form-control"
                        id="bio"
                        rows="3"
                        value={formData.bio}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-success">Save</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

