import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function About() {
  return (
    <div>
        <Header/>
        <section class="section sponsors">
	<div class="container">
		<div class="row justify-content-left">
			<div class="col-12">
				<div class="section-title text-left">
					<h1>&nbsp;</h1>
					<h2>About us</h2>
                    
                    <p>Welcome to the Houston Chargers, your premier destination for cricket excellence in Houston and beyond.</p>

                    <h2 class="mt-4">Our Mission</h2>
                    <p>At Houston Chargers, our mission is to foster a love for cricket and develop the next generation of talented players. We strive for excellence on and off the field, promoting sportsmanship, teamwork, and dedication.</p>

                    <h2 class="mt-4">Our History</h2>
                    <p>Established in 2010, the Houston Chargers has quickly risen to prominence in the local and national cricket scene. With a strong foundation built on passion and commitment, we continue to grow and thrive.</p>

                    <h2 class="mt-4">Our Team</h2>
                    <p>Our team comprises experienced coaches, dedicated players, and passionate supporters, all united in their love for cricket. Together, we work tirelessly to achieve our goals and represent Houston with pride.</p>

                    <h2 class="mt-4">Contact Us</h2>
                    <p>If you have any questions or inquiries, please don't hesitate to contact us:</p>
                    <div>
                        <p>Email: info@houstonchargers.com</p>
                        <p>Phone: +1-832-620-4299</p>
                        <p>Address: 00 Padon Rd, Needville, TX, 77461</p>
                    </div>
				</div>
			</div>
		</div>
	</div>
</section>
        <Footer/>
    </div>
  )
}
