import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Privacy() {
  return (
    <div>
        <Header/>
        <section class="section sponsors">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12">
				<div class="section-title text-left">
                    <h1 class="mt-4">Privacy Policy</h1>
                    <p class="text-muted">Last updated: 7/1/2024 12:00:00 AM</p>

                    <h2 class="mt-4">Introduction</h2>
                    <p>Houston Chargers Cricket Academy ("us", "we", or "our") operates the houstonchargers.com website (hereinafter referred to as the "Service").</p>

                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

                    <h2 class="mt-4">Information Collection and Use</h2>
                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                    <h3>Types of Data Collected</h3>
                    <h4>Personal Data</h4>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
                    <ul>
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Phone number</li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                    </ul>
				</div>
			</div>
		</div>
	</div>
</section>
    <Footer/>
    </div>
  )
}
