import React, { useEffect } from 'react';
import player1 from '../Images/team/ronald_robinson.jpg'
import player2 from '../Images/team/yameen_jehanzeb.jpg'
import player3 from '../Images/team/syed_adnanullah.jpg'
import player4 from '../Images/team/aasif_syed.jpg'
import player5 from '../Images/team/akber_hussain.jpg'
import player6 from '../Images/team/aroon_samuel.jpg'
import player7 from '../Images/team/muhammad_qhayam.jpg'
import player8 from '../Images/team/syed_suhaib_ali.jpg'
import player9 from '../Images/team/asad_zaidi.jpg'
import player10 from '../Images/team/muthaher_mirza.jpg'
import player11 from '../Images/team/rehan_ahmed.jpg'
import player12 from '../Images/team/nikhil_dutta.jpg'
import player13 from '../Images/team/shehzad_masih.jpg'
import player14 from '../Images/team/rohan_patel.jpg'
import player15 from '../Images/team/umair_khalid.jpg'
import player16 from '../Images/team/haris_latif.jpg'
import player17 from '../Images/team/zaki_rizvi.jpg'
import player18 from '../Images/team/zakir_qureshi.jpg'
import player19 from '../Images/team/zeeshan_ahmed.jpg'
import player20 from '../Images/team/khalid_muneer.jpg'
import player21 from '../Images/team/urooj_shamim.jpg'
import player22 from '../Images/team/muthaher_mirza.jpg'
import player23 from '../Images/team/zahid_hussain.jpg'
import './Style.css';
import Header from './Header';
import Footer from './Footer';
export default function Team() {


  return (
    <div>
<Header/>
<main>
<section className="section teams">
	<div className="container" style={{paddingTop:"25px"}}>
		<div className="row justify-content-center">
			<div className="col-12">
				<div className="section-title text-center">
					<h1>Houston Chargers</h1>
					<h2>Meet the Faces Behind Our Success</h2>
					<p className="mb-0">
						Get to know the talented individuals who make up our team.<br/>A blend of skill, passion, and determination, ready to conquer every challenge on and off the field.
					</p>
				</div>
			</div>
		</div>

		<div className="row position-relative">
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player1} alt="Ronald Robinson" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Ronald Robinson (c)</h3>
						<p>Top Order Batsman</p>
					</div>
				</div>
			</div>	
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player2} alt="Yameen Jehanzeb" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Yameen Jehanzeb</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player3} alt="Syed Adnanullah" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Syed Adnanullah</h3>
						<p>Bowler Right Arm Slow</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player4} alt="Aasif Syed" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Aasif Syed</h3>
						<p>Top Order Batsman</p>
					</div>
				</div>
			</div>
		</div>

		<div className="row position-relative">
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player5} alt="Akber Hussain" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Akber Hussain</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>		
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player6} alt="Aroon Samuel" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Aroon Samuel</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src="images/team/priyank_chauhan.jpg" alt="Priyank Chohan" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Priyank Chohan</h3>
						<p>Team Director/Coach</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player7} alt="MOHAMMAD QHYAAM" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Mohammad Qhyaam</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
		</div>



		<div className="row position-relative">
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player8} alt="Syed Suhaib Ali" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Syed Suhaib Ali</h3>
						<p>Bowler Left Arm Slow</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player9} alt="Asad Zaidi" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Asad Zaidi</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player10} alt="Mutaher Mirza" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Mutaher Mirza</h3>
						<p>Top Order Batsman</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player11} alt="Rehan Ahmed" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Rehan Ahmed</h3>
						<p>Right Arm Medium Fast</p>
					</div>
				</div>
			</div>
		</div>

		<div className="row position-relative">
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player12} alt="Nikhil Dutta" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Nikhil Dutta</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player13} alt="Shehzad Masih" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Shehzad Masih</h3>
						<p>Medium Fast Bowler</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player14} alt="Rohan Patel" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Rohan Patel</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player15} alt="Umair Khalid" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Umair Khalid</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
		</div>

		<div className="row position-relative">
			

			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player16} alt="Haris Latif" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Haris Latif</h3>
						<p>Fast Bowler</p>
					</div>
				</div>
			</div>

			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player17} alt="Zaki Rizvi" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Zaki Rizvi</h3>
						<p>Top Order Batsman</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player18} alt="Zakir Qureshi" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Zakir Qureshi</h3>
						<p>Bowler Medium Fast</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player19} alt="Zeeshan Ahmed" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Zeeshan Ahmed</h3>
						<p>Middle Order Batsman</p>
					</div>
				</div>
			</div>
		</div>

		<div className="row position-relative">
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player20} alt="Khalid Muneer" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Khalid Muneer</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player21} alt="Urooj Shamim" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Urooj Shamim</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player22} alt="Mutaher Mirza" className="rounded w-100" width="300" height="333"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Mutaher Mirza</h3>
						<p>Top Order Batsman</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-lg-4 col-md-6 mt-4">
				<div className="card bg-transparent border-0 text-center">
					<div className="card-img">
						<img loading="lazy" decoding="async" src={player23} alt="Zahid ur Rehman" className="rounded w-100" width="300" height="332"/>
						<ul className="card-social list-inline">
							<li className="list-inline-item">
								<a className="facebook" href="#"><i className="fab fa-facebook"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="twitter" href="#"><i className="fab fa-twitter"></i></a>
							</li>
							<li className="list-inline-item">
								<a className="instagram" href="#"><i className="fab fa-instagram"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-body">
						<h3>Zahid ur Rehman</h3>
						<p>All Rounder</p>
					</div>
				</div>
			</div>
		</div>


	</div>
</section>
</main>
<Footer/>
</div>
  )
}