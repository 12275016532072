import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showResendEmailButton, setShowResendEmailButton] = useState(false);

    const Signin = () => {
        navigate('/signup', { replace: true });
    };

    useEffect(() => {
        const token = Cookies.get('result');
        if (token) {
            console.log('Token found in cookies:', token);
            window.runtime = window.runtime || {};
            window.runtime.token = token;
        }

        // Show success message if present in state
        if (location.state && location.state.successMessage) {
            toast.success(location.state.successMessage, {
                className: 'toast-success',
                position: 'top-right',
                autoClose: 5000
            });
        }
    }, [location.state]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setShowResendEmailButton(false);
        setErrorMessage('');
        const loginUrl = process.env.REACT_APP_API_LOGIN_URL;
        try {
            const token = Cookies.get('result');
            const response = await axios.post(loginUrl, {
                Email,
                Password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}` 
                },
            });

            if (response.status === 200) {
                const { accessToken, redirectUrl } = response.data;

                // Save the access token in cookies
                Cookies.set('result', accessToken.data);
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

                navigate(redirectUrl, { replace: true });
            } else {
                setErrorMessage('Invalid credentials');
                toast.error('Invalid credentials', {
                    className: 'toast-error',
                    position: 'top-right',
                    autoClose: 5000
                });
            }
        } catch (error) {
            console.error('Login failed:', error);

            if (error.response && error.response.data) {
                const errorMessage = error.response.data;
                setErrorMessage(errorMessage);
                toast.error(errorMessage, {
                    className: 'toast-error',
                    position: 'top-right',
                    autoClose: 5000
                });

                if (errorMessage === 'Email is not confirmed. Please check your email for confirmation.') {
                    setShowResendEmailButton(true);
                }
            } else {
                setErrorMessage('An unexpected error occurred.');
                toast.error('An unexpected error occurred.', {
                    className: 'toast-error',
                    position: 'top-right',
                    autoClose: 5000
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && (
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-border text-primary avatar-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}
            <Header />
            <ToastContainer />

            <section className="section">
                <div className="container mt-5 pt-3">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <h2 className="text-center mb-4">Login</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="Email" className="form-label">Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Email"
                                        name="Email"
                                        value={Email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter email"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password-input" className="form-label">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password-input"
                                        name="Password"
                                        value={Password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter password"
                                    />
                                </div>
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>
                                        {loading ? 'Signing In...' : 'Sign In'}
                                    </button>
                                </div>
                                <div className="text-center mt-3">
                                    <a href="#!">Forgot Password?</a>
                                </div>
                            </form>
                            <div className="text-center mt-3">
                                Don't have an account? <a href="" onClick={Signin}>Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

