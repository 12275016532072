import React from 'react'
import Header from './Header'
import Footer from './Footer'
import image1 from '../Images/sponsors/depauseit.jpg'
import image2 from '../Images/sponsors/prepprime.jpg'
import image3 from '../Images/sponsors/steakspot.jpg'
import image4 from '../Images/sponsors/xpotential.jpg'
export default function Sponsers() {
  return (
    <div>
        <Header/>
        <section class="section sponsors">
	<div class="container">
		<div class="row justify-content-left">
			<div class="col-12">
				<div class="section-title text-left">
					<h1>Houston Chargers</h1>
					<h2>Our Sponsors</h2>
					<p class="mb-0">
						Join us as a sponsor of Houston Chargers and elevate your brand to new heights on the global stage. As a sponsor, you'll enjoy unparalleled visibility and numerous opportunities to connect with millions of passionate cricket fans worldwide.
					</p>

				</div>
			</div>
			<hr/>
			<div class="row position-relative">
				<div class="col-xl-3 col-lg-4 col-md-6 mt-4">
					<div class="card bg-transparent border-0 text-center">
						<div class="card-img">
							<img loading="lazy" decoding="async" src={image4} alt="Xpotential" class="rounded w-100" width="300" height="332"/>
							<ul class="card-social list-inline">
								<li class="list-inline-item">
									<a class="facebook" href="#"><i class="fab fa-facebook"></i></a>
								</li>
								<li class="list-inline-item">
									<a class="twitter" href="#"><i class="fab fa-twitter"></i></a>
								</li>
								<li class="list-inline-item">
									<a class="instagram" href="#"><i class="fab fa-instagram"></i></a>
								</li>
							</ul>
						</div>
						<div class="card-body">
							<h3>Xpotential</h3>
							<p>Official Sponsor</p>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-lg-4 col-md-6 mt-4">
					<div class="card bg-transparent border-0 text-center">
						<div class="card-img">
							<img loading="lazy" decoding="async" src={image2} alt="PrepPrime" class="rounded w-100" width="300" height="333"/>
							<ul class="card-social list-inline">
								<li class="list-inline-item">
									<a class="facebook" href="#"><i class="fab fa-facebook"></i></a>
								</li>
								<li class="list-inline-item">
									<a class="twitter" href="#"><i class="fab fa-twitter"></i></a>
								</li>
								<li class="list-inline-item">
									<a class="instagram" href="#"><i class="fab fa-instagram"></i></a>
								</li>
							</ul>
						</div>
						<div class="card-body">
							<h3>Prep Prime</h3>
							<p>Logistics Partner</p>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-lg-4 col-md-6 mt-4">
					<div class="card bg-transparent border-0 text-center">
						<div class="card-img">
							<img loading="lazy" decoding="async" src={image3} alt="Steak Spot" class="rounded w-100" width="300" height="332"/>
							<ul class="card-social list-inline">
								<li class="list-inline-item">
									<a class="facebook" href="#"><i class="fab fa-facebook"></i></a>
								</li>
								<li class="list-inline-item">
									<a class="twitter" href="#"><i class="fab fa-twitter"></i></a>
								</li>
								<li class="list-inline-item">
									<a class="instagram" href="#"><i class="fab fa-instagram"></i></a>
								</li>
							</ul>
						</div>
						<div class="card-body">
							<h3>Steak Spot</h3>
							<p>Food Partner</p>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-lg-4 col-md-6 mt-4">
					<div class="card bg-transparent border-0 text-center">
						<div class="card-img">
							<img loading="lazy" decoding="async" src={image1} alt="DepauseIT" class="rounded w-100" width="300" height="332"/>
							<ul class="card-social list-inline">
								<li class="list-inline-item">
									<a class="facebook" href="#"><i class="fab fa-facebook"></i></a>
								</li>
								<li class="list-inline-item">
									<a class="twitter" href="#"><i class="fab fa-twitter"></i></a>
								</li>
								<li class="list-inline-item">
									<a class="instagram" href="#"><i class="fab fa-instagram"></i></a>
								</li>
							</ul>
						</div>
						<div class="card-body">
							<h3>DepauseIT</h3>
							<p>IT Partner</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
        <Footer/>
    </div>
  )
}
