import React, { useEffect } from 'react';
import image1 from '../Images/logo.svg';
import './Style.css';
import {  useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
export default function Store() {


  return (
    <div>
<Header/>
<section class="section store">
<div class="container">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="section-title text-center">
                <h1>Houston Chargers</h1>
                <h2>Store</h2>
                <p class="mb-0">
                    Houston Chargers Store coming soon.
                </p>
            </div>
        </div>
    </div>
</div>
</section>



<Footer/>
</div>
  )
}



