import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Constitution() {
  return (
    <div>
        <Header/>
        
        <section class="section constiution">
	<div class="container">
		<div class="row justify-content-left">
			<div class="col-12">
				<div class="section-title text-left">
					<h1>Constitution of the Houston Chargers Sports Club</h1>
					<h2>Preamble</h2>
					<p class="mb-0">
						We, the members of the Houston Chargers, recognizing the intrinsic value of team sport that transcends boundaries and fosters unity, establish this constitution to govern our club. We are committed to upholding the principles of <b><u>FAIR PLAY</u></b>, <b><u>RESPECT FOR DIVERSITY</u></b>, and the <b><u>SPIRIT OF THE GAME</u></b> in all our endeavors.
					</p>
					<h2>Article I: Name and Purpose</h2>
					<p>The name of this organization shall be the Houston Chargers Sports, LLC. herein referred to as the Club.<br/>The purpose of the Club shall be to promote and develop the physical and sports activity in the Houston area, fostering an inclusive environment where sports men/women of all backgrounds and skill levels can participate, compete, and grow.</p>

					<h2>Article II: Membership</h2>
					<ul>
						<li>The Club shall be open to all persons regardless of race, ethnicity, gender, religion, or skill level provided such persons conform to the values and principles embodied herein.</li>
						<li>An application submitted by those wishing to become members shall be in accordance with rules adopted by the governing body of the Club.</li>
						<li>Right to participate in Club activities, events, and decision-making processes, subject to the Club's requirements.</li>
					</ul>


					<h2>Article III: Governance</h2>
					<p>The Club shall be governed by an elected Executive Committee, under which shall serve a President, Vice President, Secretary, Treasurer and such other officers as may be deemed necessary.<br/>The Executive Committee members shall have been elected at large by the membership as provided in the Club's bylawes.<br/>This committee is administrated by the Executive Committee; being the body accountable to manage the club's affairs; as well as organizing events; handling club's finance; and punishing when needed.<br/>President of club reserves the right to over rule any decision made any/all committee's.</p>

					<h2>Article IV: Code of Conduct</h2>

					<p>
						Codes of Conduct - All Club members shall agree to be bound by a code of conduct which is fair, principled and respects diversity.<br/>Discrimination, harassment, or other unsportsmanlike conduct will not be permitted and will be referred for disciplinary action at the discretion of the President of club.<br/>Umpiring and the MemberRespects the decisions of umpires (in accordance with the Level 1 - Umpires) and demonstrate high standards of behaviour and sportsmanship for all players, coaches, parents/guardians and Match officials.
					</p>

					<h2> Article V: Spirit of Game</h2>

					<p>
						The Club shall maintain and encourage the spirit of the game, within which players, match officials and spectators should conduct themselves fairly and respect their opponents.<br/>Players will play hard and enthusiastically but always maintain respect for their opponents, teammates, and the game.<br/>The club will seek to promote an environment where a sense of fellowship and friendship is felt and fostered both on and off the field.
					</p>


					<h2>Signature</h2>

					<p>We, hereby adopt this constitution as the governing document of the Houston Chargers Sports Club.</p>

					<b>Mirza Baig,</b>
					<br/>January 05<sup>th</sup>, 2016
					<br/>
					[President of Houston Chargers Sports, LLC.]
				</div>
			</div>
		</div>
	</div>
</section>

        <Footer/>
    </div>
  )
}
