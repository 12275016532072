import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };
   

    const handleSubmit = async (e) => {
        e.preventDefault();
        const contacturl = process.env.REACT_APP_API_BASE_URL;
        try {
            const response = await axios.post(contacturl, formData);
            if (response.status === 200) {
                toast.success(response.data.Message || 'Thank you for reaching out! Our team will get back to you shortly.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            // Clear the form
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } catch (error) {
            toast.error('An error occurred while submitting the form. Please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div>
            <Header />
            <ToastContainer />
            <section className="section sponsors">
                <div className="container">
                    <div className="row justify-content-left">
                        <div className="col-12">
                            <div className="section-title text-left">
                                <h1>&nbsp;</h1>
                                <h2>Contact Us</h2>
                                <p>Feel free to reach out to us with any questions, inquiries, or feedback. We're here to help!</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter your name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email address</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter your email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="message" className="form-label">Message</label>
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            rows="3"
                                            placeholder="Enter your message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                                <div id="map" className="mt-4">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.050255545246!2d-95.84868418480394!3d29.44260338207373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640d4762cf73d53%3A0x34267cd5c12502d!2sHouston%20Chargers%20Cricket%20Academy%20and%20Team!5e0!3m2!1sen!2suk!4v1640266283439!5m2!1sen!2suk"
                                        width="600"
                                        height="450"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

