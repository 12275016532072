import React ,{useState} from 'react'
import Header from './Header'
import Footer from './Footer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
export default function SignUp() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        UserName:'',
          Email: '',
          Password: ''
        });
        const {UserName, Email, Password } = formData;
        const [loading, setLoading] = useState(false);
        const [errorMessages, setErrorMessages] = useState([]);
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const registerUrl = process.env.REACT_APP_API_REGISTER_URL;
      try {
        const response = await axios.post(registerUrl, {
          UserName,
          Email,
          Password
        } , {
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
        toast.success(response.data, {
          className: 'toast-success',
          position: 'top-right',
          autoClose: 5000
        });
  
        navigate('/Login', { replace: true, state: { successMessage: 'User registered successfully. Please check your email for confirmation.' } });
      } catch (error) {
        if (error.response && error.response.data) {
          const errorResponse = error.response.data;
  
          if (errorResponse.errors) {
            const errorMessageList = Object.values(errorResponse.errors).flat().map(error => error.description);
            errorMessageList.forEach((message) => {
              toast.error(message, {
                className: 'toast-error',
                position: 'top-right',
                autoClose: 5000
              });
            });
          } else {
            toast.error(errorResponse, {
              className: 'toast-error',
              position: 'top-right',
              autoClose: 5000
            });
          }
        } else {
          toast.error('An unexpected error occurred.', {
            className: 'toast-error',
            position: 'top-right',
            autoClose: 5000
          });
        }
      } finally {
        setLoading(false);
      }
    };
  
  
          
      const Submit = () => {
          setLoading(true);
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 2000); // Navigate to login after 2 seconds (simulating loading)
        };
        
  
    const Login=()=>{
        navigate('/login' ,{replace:true})
    }
  return (
    <div>
        <Header/>
        
<ToastContainer />
        <section className="section">
        {loading && (
        <div id="preloader">
          <div id="status">
            <div className="spinner-border text-primary avatar-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
<div className="container mt-5 pt-3">
    <div className="row">
        <div className="col-md-6 offset-md-3">
            <h2 className="text-center mb-4">Sign Up</h2>
            <form onSubmit={handleSubmit} >
                <div className="mb-3">
                    <label for="username" className="form-label">UserName</label>
                    <input type="text" className="form-control" id="username" name='UserName' value={UserName}
            onChange={handleChange} required placeholder="Enter your Full Name"/>
                </div>
                <div className="mb-3">
                    <label for="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" name='Email' value={Email}
            onChange={handleChange} required placeholder="Enter email"/>
                </div>
                <div className="mb-3">
                    <label for="password" className="form-label">Password</label>
                    <input type="password" className="form-control"  id="password-input"
                             value={Password}
                             name="Password"
                             onChange={handleChange}
                             required placeholder="Enter password"/>
                </div>
                <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg" disabled={loading}
                          >
                        {loading ? 'Signing Up...' : 'Sign Up'}</button>
                </div>
            </form>
            <div className="text-center mt-3">
                Already have an account? <a href="" onClick={()=>Login()}>Login</a>

            </div>
        </div>
    </div>
</div>
</section>
        <Footer/>
    </div>
  )
}
