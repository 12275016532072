import React from 'react'
import image1 from '../Images/logo.svg';
import {  useNavigate } from 'react-router-dom';
import Contact from './Contact';
import Login from './Login';
export default function Header() {
    const navigate = useNavigate();
    const home=()=>{
  navigate('/home' ,{replace:true})  }
  const Team=()=>{
      navigate('/team' ,{replace:true})  }
      const Store=()=>{
          navigate('/store' ,{replace:true})  }
    const Fixture=()=>{
        navigate('/fixture' ,{replace:true})  }
        const Sponser=()=>{
            navigate('/sponser' ,{replace:true})  }
    const About =()=>{
        navigate('/about' ,{replace:true})  }
    const Contact=()=>{
        navigate('/contact' ,{replace:true})  }
    const SignUp=()=>{
        navigate('/signup' ,{replace:true})  
    }
    const Login=()=>{
        navigate('/login' ,{replace:true})
    }
  return (
    <div>        <header b-rkosd1qa8t="" className="navigation bg-tertiary fixed-top">
    <nav b-rkosd1qa8t="" className="navbar navbar-expand-xl navbar-light text-center py-3 navbar-fixed-top" style={{zIndex: 1000}}>

    <div b-rkosd1qa8t="" className="container">
        <a b-rkosd1qa8t="" className="navbar-brand" href="index.htm">
            <img b-rkosd1qa8t="" loading="prelaod" decoding="async" className="img-fluid" width="160" src={image1} alt="Wallet"/>
        </a>
        <button b-rkosd1qa8t="" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span b-rkosd1qa8t="" className="navbar-toggler-icon"></span>
        </button>
        <div b-rkosd1qa8t="" className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul b-rkosd1qa8t="" className="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t className="nav-link" href="" onClick={()=>home()}>Home</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Team()}>Team</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Store()}>Store</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Fixture()}>Fixtures</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=> Sponser()}>Sponsors</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" href="#!">Follow</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>About()}>About</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Contact()}>Contact</a>
                    </li>
            </ul>
            <a b-rkosd1qa8t="" href=""onClick={()=>Login()} className="btn btn-outline-primary" >Log In</a>
           <a b-rkosd1qa8t="" href="" onClick={()=>SignUp()} className="btn btn-primary ms-2 ms-lg-3">Sign Up</a>
        </div>
    </div>
</nav>
</header></div>
  )
}
