import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Fixture() {
  return (
    <div>
        <Header/>
        <section class="section fixtures">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12">
				<div class="section-title text-center">
					<h1>Houston Chargers</h1>
					<h2>Fixtures</h2>
					<p class="mb-0">
						Houston Chargers complete fixture coming soon.
					</p>
				</div>
			</div>
		</div>
	</div>
</section>
        <Footer/>
    </div>
  )
}
