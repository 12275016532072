

import React, { useEffect  ,useState} from 'react';
import image1 from '../Images/logo.svg';
import user1 from '../Images/download.png'
import houstonOpenLogo from '../Images/houston_open_logo.png';
import houstonChargersLogo from '../Images/houston_chargers_logo.png';
import xpPotentialLogo from '../Images/xpotential_logo.png';
import image2 from '../Images/slider-4.jpg';
import image3 from '../Images/slider-1.jpg';
import image4 from '../Images/slider-2.jpg';
import image5 from '../Images/slider-3.jpg';
import './Style.css'
import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import {  useNavigate } from 'react-router-dom';
import Footer from './Footer';
export default function Dash() {
    const navigate = useNavigate();
    // const [formData, setFormData] = useState({
    //   id: '',
    //   firstName: '',
    //   email: '',
    //   userType:''
     
    // });

  //   useEffect(() => {
  //     const fetchUserData = async () => {
  //       const token = Cookies.get('result');
  //       if (!token) {
  //         console.error('Access token not found');
  //         return;
  //       }
  
  //       try {
  //         const userId = getUserIdFromToken(token);
  //         if (!userId) {
  //           console.error('Failed to extract user ID from access token.');
  //           return;
  //         }
  
  //         console.log('User ID:', userId);
  //         const getUserUrl = process.env.REACT_APP_API_GET_USER_URL;
  //         const response = await axios.get(`${getUserUrl}/${userId}`, {
  //           headers: { Authorization: `Bearer ${token}` }
  //         });
  
  //         const user = response.data; // Assuming single user data is returned
  //         setFormData({
  //           id: user.id,
  //           firstName: user.firstName,
  //           email: user.email,
  //           userType :user.userType
  //         });
  //       } catch (error) {
  //         console.error('Failed to fetch user data:', error);
  //       }
  //     };
  
  //     fetchUserData();
  //   }, []); // Fetch data only once on component mount
  //   const getUserIdFromToken = (token) => {
  //     try {
  //         const decodedToken = jwtDecode(token);
  //         const userId = decodedToken.sub; // Assuming 'sub' is the user ID claim in the token
  //         return userId;
  //     } catch (error) {
  //         console.error('Error decoding access token:', error);
  //         return null;
  //     }
  // };
  // // Empty dependency array ensures useEffect runs once on mount

    
    const home=()=>{
        navigate('/home' ,{replace:true})  }
        const Team=()=>{
            navigate('/team' ,{replace:true})  }
            const Store=()=>{
                navigate('/store' ,{replace:true})  }
          const Fixture=()=>{
              navigate('/fixture' ,{replace:true})  }
              const Sponser=()=>{
                  navigate('/sponser' ,{replace:true})  }
          const About =()=>{
              navigate('/about' ,{replace:true})  }
          const Contact=()=>{
              navigate('/contact' ,{replace:true})  }
              const handleLogout = () => {
      navigate('/home', { replace: true });
    }
    const profile =()=>{

    navigate('/profile' ,{replace : true})}
    useEffect(() => {
        const loadVegas = () => {
          if (window.jQuery) {
            const $ = window.jQuery;
            var slideline4 = [
                `<img src='${houstonOpenLogo}' />`,
                `<img src='${houstonChargersLogo}' />`,
                `<img src='${houstonChargersLogo}' />`,
                `<img src='${xpPotentialLogo}' />`
              ];
      
              var slideline1 = [
                "Houston Open 2024",
                "Xpotential Park",
                "Priyank Chohan",
                "Xpotential"
              ];
      
              var slideline2 = [
                "Houston Chargers will be participating in Houston Open 2024",
                "Houston Chargers Home ground",
                "Team Director/Coach",
                "Marketing Group"
              ];
      
              var slideline0 = [
                "Houston Chargers will be participating in this year's USACA sanctioned event.",
                "Cultivating a passion for cricket, skill development, sportsmanship, and teamwork.",
                "We’re thrilled to welcome <a href='https://www.instagram.com/priyank.c36/' target='_blank'>Priyank Chohan</a>. Team Director and Coach.",
                "Innovative marketing solutions, maximizing client reach and engagement."
              ];
            $('#slider').vegas({
              slides: [
                { src: image4},
                { src: image3 },
                { src: image2 },
                { src: image5 },
              ],
              overlay: '../../plugins/vegas/overlays/02.png',
              delay: 8000,
              animation: ['kenburnsUp', 'kenburnsDown', 'kenburnsLeft', 'kenburnsRight'],
              shuffle: false,
              walk: function (index) {
                $('.head0').html(slideline4[index]);
                $('.head1').html(slideline1[index]);
                $('.head2').html(slideline2[index]);
                $('.head3').html(slideline0[index]);
              }
            });
          }
        };
    
        const script = document.createElement('script');
        script.src = '../../plugins/vegas/vegas.js';
        script.onload = loadVegas;
        document.body.appendChild(script);
    
        
        
    
        return () => {
          // Clean up the script
          document.body.removeChild(script);
        };
      }, []);

  return (
    <div>
    <div>        <header b-rkosd1qa8t="" className="navigation bg-tertiary fixed-top">
    <nav b-rkosd1qa8t="" className="navbar navbar-expand-xl navbar-light text-center py-3 navbar-fixed-top" style={{zIndex: 1000}}>

    <div b-rkosd1qa8t="" className="container">
        <a b-rkosd1qa8t="" className="navbar-brand" href="index.htm">
            <img b-rkosd1qa8t="" loading="prelaod" decoding="async" className="img-fluid" width="160" src={image1} alt="Wallet"/>
        </a>
        <button b-rkosd1qa8t="" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span b-rkosd1qa8t="" className="navbar-toggler-icon"></span>
        </button>
        <div b-rkosd1qa8t="" className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul b-rkosd1qa8t="" className="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t className="nav-link" href="" onClick={()=>home()}>Home</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Team()}>Team</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Store()}>Store</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Fixture()}>Fixtures</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=> Sponser()}>Sponsors</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" href="#!">Follow</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>About()}>About</a>
                    </li>
                    <li b-rkosd1qa8t="" className="nav-item ">
                        <a b-rkosd1qa8t="" className="nav-link" onClick={()=>Contact()}>Contact</a>
                    </li>
            </ul>
            <div className="dropdown ms-sm-3 header-item topbar-user">
            <a b-rkosd1qa8t=""  className="btn btn-primary ms-2 ms-lg-3" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <span className="d-flex align-items-center">
                    <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar"/>
                    <span className="text-start ms-xl-2">
                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">UserName</span>
                    
                    </span>
                </span>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
            
                <h6 className="dropdown-header">Welcome!</h6>
                <a className="dropdown-item" href="" onClick={()=>profile()} ><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" ></i> <span className="align-middle">Profile</span></a>
                <a className="dropdown-item" href="#!"><i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Help</span></a>
                <div className="dropdown-divider"></div>
               
                <a className="dropdown-item" href="#!"><i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></a>
                <a className="dropdown-item" href="" onClick={()=>handleLogout()}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1" ></i> <span className="align-middle" data-key="t-logout">Logout</span></a>
            </div>
        </div>
        </div>
    </div>
</nav>
</header></div>
<main>
<div id="slider">
        <div className="slider-text">
          <div className="head0"></div>
          <div className="head1"></div>
          {/* <div className="head2"></div> */}
          <div className="head3" ></div>
        </div>
      </div>
</main>


<Footer/>
</div>
  )
}