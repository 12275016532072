import React, { useEffect } from 'react';
import houstonOpenLogo from '../Images/houston_open_logo.png';
import houstonChargersLogo from '../Images/houston_chargers_logo.png';
import xpPotentialLogo from '../Images/xpotential_logo.png';
import image2 from '../Images/slider-4.jpg';
import image3 from '../Images/slider-1.jpg';
import image4 from '../Images/slider-2.jpg';
import image5 from '../Images/slider-3.jpg';
import './Style.css'
import Header from './Header';
import Footer from './Footer';
export default function Home() {

    useEffect(() => {
        const loadVegas = () => {
          if (window.jQuery) {
            const $ = window.jQuery;
            var slideline4 = [
                `<img src='${houstonOpenLogo}' />`,
                `<img src='${houstonChargersLogo}' />`,
                `<img src='${houstonChargersLogo}' />`,
                `<img src='${xpPotentialLogo}' />`
              ];
      
              var slideline1 = [
                "Houston Open 2024",
                "Xpotential Park",
                "Priyank Chohan",
                "Xpotential"
              ];
      
              var slideline2 = [
                "Houston Chargers will be participating in Houston Open 2024",
                "Houston Chargers Home ground",
                "Team Director/Coach",
                "Marketing Group"
              ];
      
              var slideline0 = [
                "Houston Chargers will be participating in this year's USACA sanctioned event.",
                "Cultivating a passion for cricket, skill development, sportsmanship, and teamwork.",
                "We’re thrilled to welcome <a href='https://www.instagram.com/priyank.c36/' target='_blank'>Priyank Chohan</a>. Team Director and Coach.",
                "Innovative marketing solutions, maximizing client reach and engagement."
              ];
            $('#slider').vegas({
              slides: [
                { src: image4},
                { src: image3 },
                { src: image2 },
                { src: image5 },
              ],
              overlay: '../../plugins/vegas/overlays/02.png',
              delay: 8000,
              animation: ['kenburnsUp', 'kenburnsDown', 'kenburnsLeft', 'kenburnsRight'],
              shuffle: false,
              walk: function (index) {
                $('.head0').html(slideline4[index]);
                $('.head1').html(slideline1[index]);
                $('.head2').html(slideline2[index]);
                $('.head3').html(slideline0[index]);
              }
            });
          }
        };
    
        const script = document.createElement('script');
        script.src = '../../plugins/vegas/vegas.js';
        script.onload = loadVegas;
        document.body.appendChild(script);
    
        
        
    
        return () => {
          // Clean up the script
          document.body.removeChild(script);
        };
      }, []);

  return (
    <div>
<Header/>
<main>
<div id="slider">
        <div className="slider-text">
          <div className="head0"></div>
          <div className="head1"></div>
          {/* <div className="head2"></div> */}
          <div className="head3" ></div>
        </div>
      </div>
</main>


<Footer/>
</div>
  )
}
